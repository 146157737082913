<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <v-btn
            dark
            color="secondary"
            class="ma-2"
            outlined
            @click="$router.back()"
          >
            <v-icon>mdi-arrow-left</v-icon>
            Back
          </v-btn>
        </v-card-title>
        <v-card-title class="pl-6">Customer</v-card-title>
        <div class="pa-5">
          <v-form ref="form">
            <v-select
              prepend-icon="mdi-format-list-bulleted-square"
              v-model="customer.sign_up_id"
              :items="getSignup"
              clearable
              outlined
              item-text="company_name"
              item-value="id"
              label="Select Signup"
            ></v-select>
            <v-text-field
              prepend-icon="mdi-text"
              label="Description"
              v-model="customer.description"
              outlined
              :rules="[rule.required]"
            ></v-text-field>
            <v-col cols="12" class="pa-0">
              <image-upload
                class="pa-0"
                label="Logo"
                v-model="customer.logo"
              ></image-upload>
            </v-col>
            <v-select
              prepend-icon="mdi-format-list-bulleted-square"
              v-model="customer.language"
              :items="languages"
              outlined
              item-text="name"
              item-value="id"
              label="Language"
            ></v-select>
            <v-col class="d-flex align-center pa-0">
              <v-text-field
                prepend-icon="mdi-text"
                label="Username"
                v-model="customer.username"
                outlined
                :loading="usernameLoading"
                :error-messages="usernameError"
                :rules="[rule.required, rule.allowedCharacters]"
              ></v-text-field>
              <v-btn
                color="primary"
                class="ml-4 mb-8"
                @click="generateUserName"
              >
                Generate Username
              </v-btn>
            </v-col>

            <div class="d-flex align-center">
              <v-text-field
                prepend-icon="mdi-form-textbox-password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                label="Password"
                v-model="customer.password"
                outlined
                :rules="isEdit ? [] : [rule.required]"
                autocomplete="false"
                @click:append="showPassword = !showPassword"
              ></v-text-field>
              <v-btn
                color="primary"
                class="ml-4 mb-8"
                @click="generatePassword"
              >
                Generate Password
              </v-btn>
            </div>
            <v-col class="py-0">
              <v-switch
                class="mt-2"
                label="Manager"
                v-model="customer.manager"
                :true-value="1"
                inset
                :false-value="0"
              ></v-switch>
              <v-switch
                class="mt-2"
                label="Disabled"
                inset
                v-model="customer.disabled"
                :true-value="1"
                :false-value="0"
              ></v-switch>
            </v-col>
            <v-row justify="end">
              <v-btn
                color="primary"
                @click="submit"
                :disabled="loading"
                :loading="loading"
              >
                {{ isEdit ? "Edit" : "Create" }} Customer
              </v-btn>
            </v-row>
          </v-form>
        </div>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import ImageUpload from "@/components/ImageUpload.vue";
import { checkUsername } from "@/services/customerService";
import { getLanguages } from "@/services/languageService";
import rules from "@/shared/rules";
import passwordGenerator from "generate-password";
import { debounce, each, get, isEmpty } from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  metaInfo: {
    title: "Customer",
  },
  components: { ImageUpload },

  props: {
    isEdit: false,
  },

  data() {
    return {
      rule: rules,
      id: get(this.$route, "params.id"),
      model: "customer",
      showPassword: false,
      usernameError: [],
      loading: false,
      usernameLoading: false,
      counter: 0,
      customer: {
        description: null,
        username: null,
        password: null,
        sign_up_id: null,
        manager: 0,
        disabled: 0,
        language: "en",
      },
      languages: [],
    };
  },

  async mounted() {
    if (this.isEdit) {
      await this.setCustomer(this.id);

      this.customer = this.getCustomer;
    }

    const { data } = await getLanguages();
    this.languages = data;
  },

  methods: {
    ...mapActions([
      "createCustomer",
      "editCustomer",
      "setCustomers",
      "showSnackbar",
      "setCustomer",
    ]),

    async submit() {
      if (!this.$refs.form.validate() || !isEmpty(this.usernameError)) {
        this.$store.dispatch("showSnackbar", "One or more fields are invalid.");

        return;
      }

      const payload = new FormData();
      each(this.customer, (value, key) => {
        payload.append(key, value);
      });

      try {
        this.loading = true;

        this.isEdit
          ? await this.editCustomer({ id: this.id, payload })
          : await this.createCustomer(payload);

        await this.showSnackbar(
          this.isEdit
            ? "Customer successfully updated!"
            : "Customer created successfully"
        );
        this.$router.push("/app/customer");
      } catch (error) {
        console.log(error);
        await this.showSnackbar("Something went wrong");
      }
      this.loading = false;
    },

    async generateUserName(exists = false) {
      if (_.get(this.customer, "description")) {
        let username = this.customer.description.replace(/[^a-zA-Z0-9_-]/g, "");

        if (exists === true) {
          this.counter++;

          username += this.counter;
        }

        const { data } = await checkUsername({
          id: this.id,
          username,
        });

        if (data) {
          this.generateUserName(true);

          return;
        }

        this.counter = 0;
        this.customer.username = username;
      } else {
        this.$store.dispatch(
          "showSnackbar",
          "Please provide customer description to generate."
        );
      }
    },

    generatePassword() {
      this.customer.password = passwordGenerator.generate({
        length: 10,
        numbers: true,
        lowercase: true,
        uppercase: true,
        excludeSimilarCharacters: true,
      });
    },

    async validateUsername() {
      this.usernameLoading = true;

      const { data } = await checkUsername({
        id: this.id,
        username: get(this.customer, "username"),
      });

      this.usernameError = data ? ["Username already exists."] : [];
      this.usernameLoading = false;
    },
  },
  computed: {
    ...mapGetters(["getSignup", "getCustomers", "getCustomer"]),
  },

  watch: {
    "customer.username": debounce(function () {
      this.validateUsername();
    }, 500),
  },
};
</script>
<style lang="scss" scoped></style>
